<template>
    <div :class="classObj" class="app-wrapper">
        <AdminSideBar/>
        <!-- Infibo stripe -->
        <div class="administration-stripe-wrapper">
            <div class="d-inline-flex administration-stripe pt-4 pb-4">
                <div class="col-4">
                    <img class="img-fluid" src="./../../assets/img/infibo-logo-inversed.svg" width="220" alt="inFibo Logo">
                </div>
                <div class="col-8">
                    <h3 class="ms-auto administration-title m-0">{{ this.$route.meta.title() }}</h3>
                </div>
            </div>
        </div>
        <div class="main-container">
            <router-view/>
        </div>
    </div>
</template>

<script>
// import resizeHandler from '@/mixins/resizeHandler'
import AdminSideBar from '@/components/AdminSideBar'

export default {
    name: "Admin",
    components: {
        AdminSideBar,
    },
    // mixins: [resizeHandler],
    data: function () {
        return {
            activeMenuIndex: '1',
            device: 'desktop',
        }
    },
    computed: {
        isAdminSidebarCollapsed: function () {
            return this.$store.getters.IS_ADMIN_SIDEBAR_COLLAPSED
        },
        isAdminSideBarWithoutAnimation: function () {
            return this.$store.getters.IS_ADMIN_SIDEBAR_WITHOUT_ANIMATION
        },
        isMobile: function () {
            return true //this.$store.getters.DEVICE === 'mobile'
        },
        classObj() {
            return {
                hideSidebar: this.isAdminSidebarCollapsed,
                openSidebar: !this.isAdminSidebarCollapsed,
                withoutAnimation: this.isAdminSideBarWithoutAnimation,
                mobile: this.isMobile
            }
        }
    },
    mounted() {
        // Check if one of routes selected
        if(this.$route.path === '/admin' || this.$route.path === '/admin/') {
            this.$router.push('/admin/administration')
        }
    }
}
</script>

<style scoped>
.app-wrapper {
    min-height: 100vh;
}

.administration-stripe-wrapper {
    margin-top: 105px;
}
.administration-stripe {
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto;

    background-image: url('./../../assets/img/administration-stripe.jpg');
    background-color: #1f2425;
    background-repeat: no-repeat;
    background-size: cover;
}

.administration-title {
    font-weight: 400;
    padding: 10px var(--bs-gutter-x, 0.75rem) 10px 15px;
    background: rgba(50, 160, 255, 1);
    width: fit-content;
    color: white;
}

</style>
