<template>
    <div>
        <div v-if="isMobile && !isAdminSidebarCollapsed" class="drawer-bg" @click="handleClickOutside" />
        <!-- Sidebar -->
        <div class="sidebar-container">
            <el-scrollbar wrap-class="scrollbar-wrapper">
                <el-menu
                    :default-active="$route.path"
                    class="el-menu-demo"
                    :collapse="isAdminSidebarCollapsed"
                    :collapse-transition="true"
                    background-color="#F5F7F9"
                    router
                    mode="vertical">
                    <el-menu-item index="/admin/administration">
                        <i class="admin-sidebar-icon">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="tachometer-alt" class="svg-inline--fa fa-tachometer-alt fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M288 32C128.94 32 0 160.94 0 320c0 52.8 14.25 102.26 39.06 144.8 5.61 9.62 16.3 15.2 27.44 15.2h443c11.14 0 21.83-5.58 27.44-15.2C561.75 422.26 576 372.8 576 320c0-159.06-128.94-288-288-288zm0 64c14.71 0 26.58 10.13 30.32 23.65-1.11 2.26-2.64 4.23-3.45 6.67l-9.22 27.67c-5.13 3.49-10.97 6.01-17.64 6.01-17.67 0-32-14.33-32-32S270.33 96 288 96zM96 384c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm48-160c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm246.77-72.41l-61.33 184C343.13 347.33 352 364.54 352 384c0 11.72-3.38 22.55-8.88 32H232.88c-5.5-9.45-8.88-20.28-8.88-32 0-33.94 26.5-61.43 59.9-63.59l61.34-184.01c4.17-12.56 17.73-19.45 30.36-15.17 12.57 4.19 19.35 17.79 15.17 30.36zm14.66 57.2l15.52-46.55c3.47-1.29 7.13-2.23 11.05-2.23 17.67 0 32 14.33 32 32s-14.33 32-32 32c-11.38-.01-20.89-6.28-26.57-15.22zM480 384c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path></svg>
                        </i>
                        <span slot="title">{{ $t('administration') }}</span>
                    </el-menu-item>
                    <el-menu-item index="/admin/optimized-settings">
                        <i class="admin-sidebar-icon">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sliders-v-square" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-sliders-v-square fa-w-14 fa-3x"><path fill="currentColor" d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zM200 224h-24v180c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12V224H88c-13.3 0-24-10.7-24-24v-16c0-13.3 10.7-24 24-24h24v-52c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h24c13.3 0 24 10.7 24 24v16c0 13.3-10.7 24-24 24zm184 104c0 13.3-10.7 24-24 24h-24v52c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-52h-24c-13.3 0-24-10.7-24-24v-16c0-13.3 10.7-24 24-24h24V108c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v180h24c13.3 0 24 10.7 24 24v16z"></path></svg>
                        </i>
                        <span slot="title">{{ $t('optimizedSettings') }}</span>
                    </el-menu-item>
                    <el-menu-item index="/admin/users">
                        <i class="admin-sidebar-icon">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="users" class="svg-inline--fa fa-users fa-w-20" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm32 32h-64c-17.6 0-33.5 7.1-45.1 18.6 40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z"></path></svg>
                        </i>
                        <span slot="title">{{ $t('users') }}</span>
                    </el-menu-item>
                    <el-menu-item index="/admin/licenses">
                        <i class="admin-sidebar-icon">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="key" class="svg-inline--fa fa-key fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M512 176.001C512 273.203 433.202 352 336 352c-11.22 0-22.19-1.062-32.827-3.069l-24.012 27.014A23.999 23.999 0 0 1 261.223 384H224v40c0 13.255-10.745 24-24 24h-40v40c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24v-78.059c0-6.365 2.529-12.47 7.029-16.971l161.802-161.802C163.108 213.814 160 195.271 160 176 160 78.798 238.797.001 335.999 0 433.488-.001 512 78.511 512 176.001zM336 128c0 26.51 21.49 48 48 48s48-21.49 48-48-21.49-48-48-48-48 21.49-48 48z"></path></svg>
                        </i>
                        <span slot="title">{{ $t('licenses') }}</span>
                    </el-menu-item>
                    <el-menu-item index="/admin/accounts">
                        <i class="admin-sidebar-icon">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user-circle" class="svg-inline--fa fa-user-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><path fill="currentColor" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z"></path></svg>
                        </i>
                        <span slot="title">{{ $t('accounts') }}</span>
                    </el-menu-item>
                    <el-menu-item index="/admin/invites">
                        <i class="admin-sidebar-icon">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user-plus" class="svg-inline--fa fa-user-plus fa-w-20" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M624 208h-64v-64c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v64h-64c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h64v64c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-64h64c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm-400 48c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"></path></svg>
                        </i>
                        <span slot="title">{{ $t('invites') }}</span>
                    </el-menu-item>
                </el-menu>

                <el-divider/>

                <!-- Logged in user -->
                <div class="d-flex align-items-center py-2" style="padding: 0 20px;">
                    <el-dropdown>
                        <span class="el-dropdown-link">
                            {{ profile.email }}<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item @click.native="viewProfile" disabled icon="el-icon-user">{{ $t('profile') }}</el-dropdown-item>
                            <el-dropdown-item @click.native="logout">
                                <img src="@/assets/icons/logout.svg" alt="Logout" width="16">
                                {{ $t('logout') }}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <!-- Lang selector -->
                <div class="d-flex align-items-center py-2" style="padding: 0 20px;">
                    <el-radio-group
                        v-model="$i18n.locale"
                        @change="changeLocale"
                        size="small">
                        <el-radio-button label="ru">RU</el-radio-button>
                        <el-radio-button label="en">EN</el-radio-button>
                    </el-radio-group>
                </div>
            </el-scrollbar>
        </div>
    </div>
</template>

<script>
import {changeLocale} from '@/plugins/i18n'

export default {
    name: "AdminSideBar",
    i18n: {
        messages: {
            en: {
                administration: 'Administration',
                optimizedSettings: 'Optimized settings',
                users: 'Users',
                licenses: 'Licenses',
                accounts: 'Accounts',
                invites: 'Invites',
                profile: 'Profile',
                logout: 'Logout',
            },
            ru: {
                administration: 'Администрирование',
                optimizedSettings: 'Оптим. настройки',
                users: 'Пользователи',
                licenses: 'Лицензии',
                accounts: 'Счета',
                invites: 'Инвайты',
                profile: 'Профиль',
                logout: 'Выход',
            }
        }
    },
    computed: {
        profile: function () {
            return this.$store.getters.PROFILE
        },
        isAdminSidebarCollapsed: function () {
            return this.$store.getters.IS_ADMIN_SIDEBAR_COLLAPSED
        },
        isMobile: function () {
            return true //this.$store.getters.DEVICE === 'mobile'
        },
    },
    methods: {
        changeLocale,
        handleClickOutside() {
            this.$store.dispatch('CLOSE_ADMIN_SIDEBAR',{ withoutAnimation: false })
        },
        // Routes
        viewProfile: function () {
            console.log('view profile')
        },
        logout: function () {
            this.$store.dispatch('SESSION_DELETE_REQUEST')
                .finally(() => this.$router.push('/auth/login'))
        },
    },
}
</script>

<style lang="scss" scoped>
@import '../styles/adminSidebar.scss';

// Drawer
.drawer-bg {
    background: #000;
    opacity: 0.3;
    width: 100%;
    top: 0;
    height: 100%;
    position: fixed;
    z-index: 2001;
}

.admin-sidebar-icon {
    margin-right: 10px;

    svg {
        width: 20px;
    }
}
</style>
